export default function translate() {
    const languageActive = document.querySelector('[js-language-active]')
    const languageItens = document.querySelectorAll('[js-language-item]')


    if (!languageActive || !languageItens.length) return


    function load_tlib() {
        if (!window.gt_translate_script) {
            window.gt_translate_script = document.createElement('script');
            gt_translate_script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit2';
            document.body.appendChild(gt_translate_script);
        }
    }


    function changeLanguage(el) {
        const texto = el.getAttribute('js-language-item')
        const flag = el.getAttribute('js-flag')
        languageActive.querySelector('.language').innerText = texto
        languageActive.querySelector('.icon-flag').src = flag

        console.log(`[data-gt-lang="${texto.toLowerCase()}"]`);
        document.querySelector(`[data-gt-lang="${texto.toLowerCase()}"]`).click();

        console.log(texto)

    }


    function init() {
        languageItens
            .forEach((i) => i.addEventListener('click', ({ currentTarget }) => changeLanguage(currentTarget)))
            
        load_tlib()
        return this
    }

    return {
        init
    }
}   