export default function animations() {

  /////////SERVIÇOS/////////// 

  function menu(){
    const tl = gsap.timeline({
      defaults:{
        ease: Back.easeOut.config(1.5)
      }
    });

    tl
    .from('.itens-menu-wrapper .logo-akurey', {
      opacity: 0,
      y:40,
     
    })
    
    .from('.itens-menu-wrapper .itens-menu > *', {
      opacity: 0,
      y:40,
      stagger: 0.1
     
    },'<+=0.2')
    .from('.barra-divisora', {
      y: 20,
      opacity: 0,
    
    },'<+=0.2')
    .from('.gtranslate_wrapper', {
      y: 40,
      opacity: 0,
    
    },'<+=0.2')
  }

  function fold(){

    gsap.set('.akurey-intro .color', {
      y: -300,
      opacity: 0
    })

    gsap.to('.akurey-intro .color', {
      y: 122,
      opacity: 1,
      scrollTrigger:{
        trigger: '.akurey-intro',
        start: '+=30px'
      }
    })
    gsap.to('.arrow', {
      y:10,
      yoyo: true,
      repeat: -1,
      ease: 'linear',
      duration: 1.1
    })
    // rotationX: "-90deg",
		// 		transformPerspective: 500,
		// 		force3D: true,
		// 		transformOrigin: "top center",
		// 		transformStyle: "preserve-3d"
  }
  function introBanner(){
    const mobile = window.matchMedia('(max-width:1109px)').matches
   
    const tl = gsap.timeline({
      defaults:{
        ease: Back.easeOut.config(2)
      }
    });





     if(mobile){
      gsap.set('.img-wrapper-2 img', {
     
        borderRadius: '0px 128px'
      } )
      gsap.set('.img-wrapper-3 img', {
        
        borderRadius: '128px 0px 0px 128px'
      })
      gsap.set('.img-wrapper-4 img', {
        
           
        borderRadius: '0px 0px 128px 128px'
      })
      gsap.set('.img-wrapper-5 img', {
        
           
        borderRadius: '0px 128px'
      })
      gsap.set('.img-wrapper-6 img', {
        
           
        borderRadius: '0px 128px 0px 0px'
      })
      gsap.set('.img-wrapper-7 img', {
        
           
        borderRadius: '50%'
      })
      gsap.set('.img-wrapper-8 img', {
        borderRadius: '222px 0px 222px 222px;'
      })
     return
    }
    // gsap.set('.banner-img-wrapper', {
    //   opacity: 0,
    //   x: 40
    //   // clipPath: 'circle(100%)'
    // })

    gsap.set('.itens-right .title-top h1', {
     clipPath: 'inset(0px 100% 0px 0px)',
     opacity: 0
    })
    
    tl    
    .to('.itens-right .title-top h1', {
      clipPath: 'inset(0px 0% 0px 0px)',
      opacity: 1,
      duration: 1,
      ease: 'power2'
      // opacity: 0,
      // y: 40
    }, 0.2)
    .from('.itens-right .title-top .text-paragraph-2', {
      y: 20,
      opacity: 0,
      ease: 'power2'
      // opacity: 0,
      // y: 40
    }, '<+=0.2')
    .from('.itens-right .redes-sociais .text-paragraph-2 ', {
      opacity: 0,
      y: 20
    },'<+=0.2')
    .from('.itens-right .redes-sociais .icons-redes-sociais-wrapper > a', {
      opacity: 0,
      y: 20,
      stagger: 0.1
    },'<+=0.2')
    .from('.itens-right  .btn-contato', {
      opacity: 0,
      y: 20,
      
    },'<+=0.2')
    
   
   
    // .to('.banner-img-wrapper', {
    //   opacity:1,
    //   x: 0
    //   // duration: 1,
    //   // ease: 'none',
    //   // clipPath: 'circle(0%)'
    // }, '<+=0.2')

    .to('.img-wrapper-2 img', {
      ease: 'linear',
      borderRadius: '0px 128px'
  
    },'<+=0.2')
    .to('.img-wrapper-3 img', {
       ease: 'linear',
      borderRadius: '128px 0px 0px 128px'
    }, '<')
    .to('.img-wrapper-4 img', {
      
         ease: 'linear',
      borderRadius: '0px 0px 128px 128px'
    }, '<')
    .to('.img-wrapper-5 img', {
      
         ease: 'linear',
      borderRadius: '0px 128px'
    }, '<')
    .to('.img-wrapper-6 img', {
      
         ease: 'linear',
      borderRadius: '0px 128px 0px 0px'
    }, '<')
    .to('.img-wrapper-7 img', {
      
         ease: 'linear',
      borderRadius: '50%'
    }, '<')
    .to('.img-wrapper-8 img', {
      ease: 'linear',
      borderRadius: '222px 0px 222px 222px;'
    }, '<')


    .from('.ornamento-3', {
      // x: 100,
      // rotate: 180,
      duration: 1,
      opacity: 0
    },'<+=0.2')





    const tl2 = gsap.timeline({
      defaults:{
        ease: 'none'
      }
    });

  


  

    // tl2
    // .to('.banner-img-wrapper .img-wrapper img', {
    //   borderRadius: 0,
    //   yoyo: true,
    //   repeat: -1,
    //   duration: 1,
    //   y:5,
    
    //   stagger: {
    //     grid: [3,3],
    //     each: 0.1,
    //     from: "random",
    //     // ease: "power2.in",
    //     amount: 0.5
    //   }

    // })
  
    
  
   

   
  }

  function timeline(){
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.our-story-pinss',
           scrub: 1,
          start: "top 50%",
          end: "bottom 50%",      
        }
    })
    tl.to('.back', { height: '100%'})

    gsap.from('.our-story-pinss .our-story-1', {
      opacity: 0,
      y: 20,
      scrollTrigger: {
        trigger: '.our-story-pinss .our-story-1',
          start: "top 60%",      
        }

    })
    gsap.from('.our-story-pinss .our-story-2', {
      opacity: 0,
      y: 20,
      scrollTrigger: {
        trigger: '.our-story-pinss .our-story-2',       
          start: "top 60%",   
        }

    })
  }


  document.addEventListener("DOMContentLoaded", () => {
    menu();
    fold();
    introBanner();
    timeline();
  });

}
