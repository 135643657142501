export default function menu() {
    const menu = document.querySelector('.menu-bar')
    const menuBg = document.querySelector('.menu-bg')
    const headerActive = document.querySelector('.bg-menu')
    const menuHamburguer = document.querySelector('.menu-hamburguer')
    const menuItensMobile = document.querySelector('.itens-menu-mobile')
    const backMenu = document.querySelector('.h-full')

  
    const changeMenu = ()=>{
        menuHamburguer.addEventListener('click', ()=>{
          menu.classList.toggle("change");
          menuBg.classList.toggle("change-bg");
          headerActive.classList.toggle("menu-active");
          backMenu.classList.toggle("change-container");
          menuItensMobile.classList.toggle("change");
        })
    } 

    changeMenu()
}