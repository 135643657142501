// SCRIPT SENHA LOGIN
import mostrarSenha from "./mostrarSenha.js"
import showFormErrors from "./showFormErrors.js";
import menu from "./menu.js";
import dropdown from "./dropdown.js";
import translate from "./changeLanguage.js";
import animations from "./animations/home_animation.js";
import contatoForm from "./formulaContato.js";
import animaAoScroll from "./animations/animaAoScroll.js";

gsap.registerPlugin(ScrollTrigger)

// PAGES
const pageHome = document.querySelector(".body-home");


mostrarSenha()
showFormErrors()
dropdown()
// translate()?.init()
animaAoScroll()

// ★ Pages Scopo
if (pageHome) {
  menu()
  animations()
  contatoForm();


  const arrowDown = document.querySelector('.akurey-intro .color');

  if(arrowDown){
  
    arrowDown.addEventListener('click', () => {
      console.log('rodou ?')
      const quemSomos = document.querySelector('.akurey-quem-somos');
      quemSomos?.scrollIntoView({ behavior: 'smooth'});
     
      

    })
  }

 
}

document.addEventListener("DOMContentLoaded", async () => {
  document.body.classList.add("dcl")

  const switchLang = document.querySelector('.gt_switcher_wrapper')
  if(switchLang){

    console.log('oie',switchLang)
    switchLang.addEventListener('click', () => {
      console.log('oie')
      document.body.classList.add('translate-open')

    })
  }

  //const Translate = document.querySelector('.gtranslate_wrapper');
  const LinkTranslate = document.querySelectorAll('a.glink');
  if(LinkTranslate){
    LinkTranslate.forEach((link)=>{
      link.classList.add("text-title-14");
      link.classList.add("bold");
      link.classList.add("text-primary-light");
      link.classList.add("item-menu");
    })
  }
});


AOS.init({
  disable: function () {
    var maxWidth = 768;
    return window.innerWidth < maxWidth;
  },
  startEvent: "DOMContentLoaded",
  offset: 30, // offset (in px) from the original trigger point
  delay: 100, // values from 0 to 3000, with step 50ms
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  duration: 800, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: true, // whether animation should happen only once - while scrolling down
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
});